@import '~antd/dist/antd.less';

@primary-color: #1E6599;
@layout-header-background: #062543;

@success-color: #56B461;
@warning-color: #FABE00;
@error-color: #E62D31;

@alert-error-bg-color: #FCEAEA;
@alert-warning-bg-color: #FFF8E5;
@alert-success-bg-color: #EEF7EF;
@alert-text-color: #949494;

/* Фон страниц */
@layout-body-background: #F6F6F6;

@font-face {
  font-family: 'SF Pro Display';
  src: local('SF Pro Display'), url(./assets/fonts/SF_Pro_Display/SF-Pro-Display-Regular.otf) format('opentype');
}
@font-face {
  font-family: 'SF Pro Display';
  src: local('SF Pro Display'), url(./assets/fonts/SF_Pro_Display/SF-Pro-Display-Light.otf) format('opentype');
  font-weight: 300;
}
@font-face {
  font-family: 'SF Pro Display';
  src: local('SF Pro Display'), url(./assets/fonts/SF_Pro_Display/SF-Pro-Display-Bold.otf) format('opentype');
  font-weight: bold;
}
@font-face {
  font-family: 'SF Pro Display';
  src: local('SF Pro Display'), url(./assets/fonts/SF_Pro_Display/SF-Pro-Display-RegularItalic.otf) format('opentype');
  font-style: italic;
}
@font-face {
  font-family: 'SF Pro Display';
  src: local('SF Pro Display'), url(./assets/fonts/SF_Pro_Display/SF-Pro-Display-LightItalic.otf) format('opentype');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'SF Pro Display';
  src: local('SF Pro Display'), url(./assets/fonts/SF_Pro_Display/SF-Pro-Display-BoldItalic.otf) format('opentype');
  font-weight: bold;
  font-style: italic;
}

@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';

@font-size-base: 15px;

.ant-page-header {
  padding: 0;
  margin-bottom: 12px;
}

#root {
  height: 100%;
  overflow: hidden;
}

/* BaseLayout */
.base-layout {
  height: 100vh;
}

.base-layout-sider {
  height: 100vh;
}

.base-layout-logo-container {
  padding: 20px;
}

.base-layout-logo {
  width: 100%;
  height: 26px;  
  vertical-align: middle;
}

.base-layout-logo-elma-bot {
  width: 100%;
  height: 26px;  
  vertical-align: middle;
}

.base-layout-sider .ant-menu {
  overflow: auto;
  height: calc(100% - 64px);
}

.base-layout-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  position: relative;
  background: #fff;
  padding: 0 16px;
}

.app-info {
  font-size: 12px;
  max-width: 300px;
  line-height: 1;
  margin-left: auto;
  margin-right: 10px;
}

.base-layout-main {
  height: 100%;
  overflow: hidden;
}

.base-layout-scrollable {
  height: calc(100% - 64px);
  overflow: auto;
}

.base-layout-scrollable-content {
  min-height: calc(100% - 64px);
  position: relative;
}

.base-layout-content {
  flex: none;
  margin: 24px 16px;
  padding: 24px;
  background: #fff;
  min-height: 280px;
}

.base-layout-footer {
  margin-top: auto;
  text-align: center;
}

/* Validated table */
.ant-form-item-has-error .ant-table-wrapper  {
  border: 1px solid @error-color;
}

.ant-btn-primary:hover {
  background: #367EB2;
}

.ant-btn-primary:active {
  background: #0D4A75;
}

.ant-form-item-label > label.ant-form-item-required::before {
  color: @error-color;
}
